import {
  ActionIcon, Badge,
  Button,
  Card,
  Checkbox,
  Flex,
  Image,
  LoadingOverlay,
  Space, Table,
  Text,
  Tooltip,
} from "@mantine/core";
import styled from "styled-components";
import {IAdminLog, User, assets, colors, OrderStatus, Order} from "../../constants";
import moment from "moment";
import AdminHistory from "../AdminAccount/components/AdminHistory";
import { closeModal } from "@mantine/modals";
import { AbsolutelyCentered, PageHeader } from "../../components";
import { PageWrapper } from "../../layout";
import {IconPencil, IconCopy, IconCheck, IconLink, IconClipboard} from "@tabler/icons-react";
import {Fragment, useEffect, useRef, useState} from "react";
import EditPatientForm from "./components/EditPatientForm";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useAppData } from "../../contexts/AppContext";
import { openModal } from "../../modules";
import RefundPatientForm from "./components/RefundPatientForm";
import EditInternalNote from "./components/EditInternalNote";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import {notifications} from "@mantine/notifications";
import {usePatients} from "../../hooks/usePatients";

const SHIPPING_OPTIONS = [
  { label: "Overnight UPS $33", value: "33" },
  { label: "USPS $9", value: "9" },
  { label: "UPS Ground $14", value: "14" },
  { label: "Pick Up", value: "pick-up" },
  { label: "Free USPS", value: "0" },
];

function PatientDetails() {
  const navigate = useNavigate();
  const [patient, setPatient] = useState<User | any>();
  const [logsLoading, setLogsLoading] = useState<boolean>(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedAddress, setCopiedAddress] = useState(false);
  const [copiedBillingAddress, setCopiedBillingAddress] = useState(false);
  const params = useParams();
  const {getKeyByValue} = useAppData();
  const {getLimitedPatients} =usePatients();
  const {
    //patientsData: { patients, loading },
    ordersData: { orders },
  } = useAppData();


  let tmp_order = orders?.find((o) => o.userId === patient?.id);
  const [order, setOrder] = useState(null);
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  function useDeepCompareMemoize(value) {
    const ref = useRef();
    if (JSON.stringify(value) !== JSON.stringify(ref.current)) {
      ref.current = value;
    }
    return ref.current;
  }
  const location = useLocation(); // Add this to get access to location
  const [fromOrderStatus, setFromOrderStatus] = useState(false);
  useEffect(() => {
    const previousPath = location.state?.from || '';
    setFromOrderStatus(previousPath.includes('/order-status'));
  }, [location]);
  const handleBackNavigation = () => {
    if (fromOrderStatus) {
      navigate('/order-status');
    } else {
      navigate(ROUTES.PatientLookUp.path);
    }
  };

  const viewOrder = (newOrder) => {
    //div with class  scroll to top
    document.getElementsByClassName("pt_detail_main_holder ")[0]?.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setOrder(newOrder);
    //setAllOrders(prev => [...prev.filter(o => o.id !== newOrder.id), order].filter(Boolean));
  };

  const fetchPatient = async () => {
    if (params?.patientId) {
      try {
        setLoading(true);
        const { patients: patientData } = await getLimitedPatients(null, null, [params.patientId]);
        setLoading(false);
        if (patientData?.[0]) {
          const patient = patientData[0] as any;
          setPatient({ ...patient });

          // Handle orders logic
          if (patient.orders) {
            // Find and set the most recent order
            const foundOrdersList = [...patient.orders];
            const foundOrder = foundOrdersList.sort((a, b) => {
              if (b.createdAt.seconds !== a.createdAt.seconds) {
                return b.createdAt.seconds - a.createdAt.seconds;
              }
              return b.createdAt.nanoseconds - a.createdAt.nanoseconds;
            }).shift();
            setOrder(foundOrder || null);

            // Handle all orders
            const otherOrders = [...patient.orders];
            otherOrders.sort((a, b) => {
              if (b.createdAt.seconds !== a.createdAt.seconds) {
                return b.createdAt.seconds - a.createdAt.seconds;
              }
              return b.createdAt.nanoseconds - a.createdAt.nanoseconds;
            });
            setAllOrders(otherOrders);
          } else {
            setOrder(null);
            setAllOrders([]);
          }
        } else {
          setPatient(undefined);
          setOrder(undefined);
          setAllOrders([]);
        }
      } catch (error) {
        console.error('Error fetching patient:', error);
        setPatient(undefined);
        setOrder(undefined);
        setAllOrders([]);
      }
    }
  };

  useEffect(() => {
    fetchPatient();
  }, [params?.patientId]);
  const onHistoryClick = async (p: any) => {
    let id = p?.id;
    const firstNestedCollectionRef = collection(db, "users", id, "user-logs");
    setLogsLoading(true);
    const firstNestedQuerySnapshot = await getDocs(firstNestedCollectionRef);
    setLogsLoading(false);

    let userLogs = firstNestedQuerySnapshot.docs.map((firstNestedDoc) => ({
      id: firstNestedDoc.id,
      ...(firstNestedDoc.data() as any),
    }));

    openModal({
      id: "admin-history",
      children: <AdminHistory logs={userLogs} />,
    });
  };

  const onHistoryClickOrderLogs = async (o: any) => {
    let id = o?.id;
    const firstNestedCollectionRef = collection(db, "orders", id, "order-logs");

    setLogsLoading(true);
    const firstNestedQuerySnapshot = await getDocs(firstNestedCollectionRef);
    setLogsLoading(false);

    let orderLogs = firstNestedQuerySnapshot.docs.map((firstNestedDoc) => ({
      id: firstNestedDoc.id,
      ...(firstNestedDoc.data() as any),
    }));


    let profileLogsRef = collection(db, "users", o?.userId, "profile-logs");
    const profileLogsQuerySnapshot = await getDocs(profileLogsRef);

    orderLogs = orderLogs.concat(
      profileLogsQuerySnapshot.docs.map((profileLogsDoc) => ({
        id: profileLogsDoc.id,
        ...(profileLogsDoc.data() as any),
      }))
    );

    //sort it based on date
    orderLogs.sort((a, b) => {
      if (b.createdAt.seconds !== a.createdAt.seconds) {
        return b.createdAt.seconds - a.createdAt.seconds;
      }
      return b.createdAt.nanoseconds - a.createdAt.nanoseconds;
    });

    openModal({
      id: "admin-history",
      children: <AdminHistory logs={orderLogs} />,
    });
  };
  const onEditPatient = (patient: any) => {
    openModal({
      id: "edit-patient",
      children: (
        <EditPatientForm
          initialValues={patient}
          onSubmit={() => {
            closeModal("edit-patient");
          }}
        />
      ),
      size: "calc(70vw)",
    });
  };

  const onRefund = (order: any) => {
    openModal({
      id: "refund-patient",
      children: (
        <RefundPatientForm
          type="refund"
          order={order}
          patient={patient}
          onSubmit={async() => {
            closeModal("refund-patient");
            await new Promise(resolve => setTimeout(resolve, 1000));
            await fetchPatient();
          }}
        />
      ),
      size: "calc(50vw)",
    });
  };

  const onVoid = (order: any) => {
    openModal({
      id: "void-patient",
      children: (
          <RefundPatientForm
              type="void"
              order={order}
              patient={patient}
              onSubmit={async () => {
                closeModal("void-patient");
                await new Promise(resolve => setTimeout(resolve, 1000));
                await fetchPatient();
              }}
          />
      ),
      size: "calc(50vw)",
    });
  };

  const onChargePatient = (order: any) => {
    openModal({
      id: "charge-patient",
      children: (
        <RefundPatientForm
          type="charge"
          order={order}
          patient={patient}
          onSubmit={() => {
            closeModal("charge-patient");
          }}
        />
      ),
      size: "calc(50vw)",
    });
  };

  const editInternalNotes = (order: Order) => {
    openModal({
      id: "edit-internal-notes",
      children: (
        <EditInternalNote
          order={order}
          onSubmit={() => {
            closeModal("edit-internal-notes");
          }}
        />
      ),
      size: "calc(50vw)",
    });
  };

  const copyToClipboard = (
    text: string,
    setCopied: (value: boolean) => void
  ) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };
  const openOrderLink = () => {
    let linkToOpen = process.env.REACT_APP_FORNTEND_URL+'?oid='+order?.id+'&rxNumber='+order?.rxNumber;
    copyToClipboardAddress(linkToOpen);
  }
  const copyToClipboardAddress = (text: string) => {
    navigator.clipboard.writeText(text);
    notifications.show({
      title: "Copied to clipboard",
      message: "Order link copied to clipboard.",
      color: "green",
      autoClose: 10000,
      withCloseButton: true
    })
  }
  const isRefunded = (order) => {
      if(order?.refundInfo){
        for (let i = 0; i < order.refundInfo.length; i++) {
          if(order.refundInfo[i]?.transactionId === order?.transactionId && order.refundInfo[i]?.responseCode === "1"){
            return true;
          }
        }
      }
      return false;
  }
  return (
      <PageWrapper
          footer={
            <Flex
                style={{ cursor: "pointer", marginTop: "10px" }}
                onClick={handleBackNavigation}
                align={"center"}
                gap={15}
            >
              <Image radius={"xl"} src={assets.icons.leftToggle} />
              <Back>
                {fromOrderStatus ? 'Back to Order Status' : 'Back to Patient Portal'}
              </Back>
            </Flex>
          }
      >
      <PageHeader
        searchProps={{
          placeholder: "Search By Name or Date of Birth",
          style: {
              display: 'none'
          }
        }}
        buttons={
          <Fragment>
            <Button
              radius={"xl"}
              size="xl"
              onClick={() => onEditPatient(patient)}
            >
              <span style={{ paddingRight: 5 }}>Edit Patient Details</span>
              <IconPencil />
            </Button>
          </Fragment>
        }
      />

      {loading ? (
        <LoadingOverlay visible />
      ) : patient ? (
        <Flex
            className={'pt_detail_main_holder'}
          direction={"column"}
          gap={15}
          p={20}
          style={{ overflowY: "scroll" }}
        >
          <Flex gap={10} align={"center"}>
            <Title>
              {patient?.firstName} {patient?.lastName}
            </Title>
            <Image src={assets.icons.verifiedProfile} w={24} h={24} /> |
            <Badge color={colors.primaryColor}>Order Id : {order?.id}</Badge>
            <Badge color={colors.primaryColor}> Rx Number : {order?.rxNumber}</Badge>
            <Badge onClick={openOrderLink} color={colors.primaryColor} style={{cursor:'pointer'}}> Copy Order Link</Badge>
          </Flex>
          <Flex>
            <Flex
              direction={"column"}
              flex={1}
              gap={25}
              justify={"space-between"}
            >
              <Flex direction={"column"} gap={10}>
                <Label>Date of Birth</Label>
                <Value>
                  {moment.utc(patient?.dateOfBirth).format("MM/DD/YYYY")}
                </Value>
              </Flex>

              <Flex direction={"column"} gap={10}>
                <Label>Email</Label>
                <Value>
                  {patient?.email}
                  <Tooltip label="Copy to clipboard">
                    <ActionIcon
                      onClick={() =>
                        copyToClipboard(patient?.email, setCopiedEmail)
                      }
                      variant="transparent"
                    >
                      {copiedEmail ? (
                        <IconCheck size={16} />
                      ) : (
                        <IconCopy size={16} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                </Value>
              </Flex>
              <Space h={"sm"} />
              <Flex direction={"column"} gap={10}>
                <Label>Phone Number</Label>
                <Value>{patient?.phone}</Value>
              </Flex>

              <Space h={"sm"} />
              <Flex direction={"column"} gap={10}>
                <Label>Credit Card</Label>
                <Value>
                  Last 4 digits: ***
                  {order?.paymentMethod?.cardNumber.slice(
                    order?.paymentMethod?.cardNumber?.length - 4
                  )}{" "}
                </Value>
                <Value>
                  Expiration: {order?.paymentMethod?.expirationMonth}/
                  {order?.paymentMethod?.expirationYear}
                </Value>
                {order?.transactionId &&
                    <Value>
                      Transaction Id: {order?.transactionId}
                    </Value>
                }
              </Flex>

              <Flex direction={"column"} gap={10} align={"center"}>
                <Button
                  onClick={() => onChargePatient(order)}
                  size="lg"
                  radius={"xl"}
                >
                  Charge Patient
                </Button>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <Button
                  variant="transparent"
                  td={"underline"}
                  size="lg"
                  radius={"xl"}
                  onClick={() => onRefund(order)}
                >
                  Refund Patient
                </Button>
                  {order?.transactionId != null && (
                  <Tooltip label="If the transaction is less than 24 hours old, then use the void option to refund the transaction.">
                    <Button
                    variant="transparent"
                    td={"underline"}
                    size="lg"
                    radius={"xl"}
                    onClick={() => onVoid(order)}
                >
                  Void(Refund) Patient
                </Button>
                  </Tooltip>)}
                </div>
              </Flex>
            </Flex>
            <Flex
              direction={"column"}
              justify={"space-between"}
              flex={1}
              gap={10}
            >
              <Flex direction={"column"} gap={10}>
                <Label>Shipping Address</Label>
                <Value>
                  {`${
                    patient?.address?.street
                      ? patient.address.street + ", "
                      : ""
                  }${
                    patient?.address?.city ? patient.address.city + ", " : ""
                  }${patient?.address?.state ? patient.address.state : ""}`}
                  <Tooltip label="Copy to clipboard">
                    <ActionIcon
                      onClick={() =>
                        copyToClipboard(
                          `${
                            patient?.address?.street
                              ? patient.address.street + ", "
                              : ""
                          }${
                            patient?.address?.city
                              ? patient.address.city + ", "
                              : ""
                          }${
                            patient?.address?.state ? patient.address.state : ""
                          }`,
                          setCopiedAddress
                        )
                      }
                      variant="transparent"
                    >
                      {copiedAddress ? (
                        <IconCheck size={16} />
                      ) : (
                        <IconCopy size={16} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                </Value>
              </Flex>
              <Flex direction={"column"} gap={10}>
                <Label>Billing Address</Label>
                <Value>
                  {patient?.sameAddress
                    ? "Same as Shipping Address"
                    : `${
                        patient?.billingAddress?.street
                          ? patient.billingAddress.street + ", "
                          : ""
                      }${
                        patient?.billingAddress?.city
                          ? patient.billingAddress.city + ", "
                          : ""
                      }${
                        patient?.billingAddress?.state
                          ? patient.billingAddress.state
                          : ""
                      }`}
                  <Tooltip label="Copy to clipboard">
                    <ActionIcon
                      onClick={() =>
                        copyToClipboard(
                          `${
                            patient?.sameAddress
                              ? "Same as Shipping Address"
                              : `${
                                  patient?.billingAddress?.street
                                    ? patient.billingAddress.street + ", "
                                    : ""
                                }${
                                  patient?.billingAddress?.city
                                    ? patient.billingAddress.city + ", "
                                    : ""
                                }${
                                  patient?.billingAddress?.state
                                    ? patient.billingAddress.state
                                    : ""
                                }`
                          }`,
                          setCopiedBillingAddress
                        )
                      }
                      variant="transparent"
                    >
                      {copiedBillingAddress ? (
                        <IconCheck size={16} />
                      ) : (
                        <IconCopy size={16} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                </Value>
              </Flex>

              <Flex direction={"column"} mt={"sm"}>
                <Text fw={700}>Questions</Text>
                {order?.questions?.length
                  ? order?.questions.map((q) => {
                      return (
                        <Flex direction={"column"} key={q.question}>
                          <Text fw={500}>{q.question}</Text>
                          <Text size="sm">
                            {q.none || !q.answer ? <em>None</em> : q?.answer}
                          </Text>
                        </Flex>
                      );
                    })
                  : null}
                <Text fw={500}>Are you Pregnant / Breastfeeding?</Text>
                {order?.pregBF ? <Text>Yes</Text> : <em>None</em>}
              </Flex>
              <Button
                loading={logsLoading}
                variant="transparent"
                td={"underline"}
                size="lg"
                radius={"xl"}
                //onClick={() => onHistoryClick(patient)}
                onClick={() => onHistoryClickOrderLogs(order)}
              >
                View Account History
              </Button>
            </Flex>
            <Flex direction={"column"} flex={2} gap={20}>
              <Card radius={"lg"} bg={colors.bg} h={"50%"}>
                <Flex
                  direction={"column"}
                  gap={25}
                  style={{ overflowY: "auto" }}
                  px={5}
                >
                  <Flex justify={"space-between"} align={"center"}>
                    <MedicationTitle>Active Medications</MedicationTitle>
                    <Flex gap={10} align={"center"}>
                      {order && Array.isArray(order.statusHistory) ?
                          <Badge
                              color={(order?.statusHistory[0]?.status === "ordered" || order?.transactionId) ? "green" : "gray"}
                              variant="light"
                          >
                            {(order?.statusHistory[0]?.status === "ordered" || order?.transactionId) ? "Ordered Date" : "Uploaded Date"}
                          </Badge>
                          : <Badge
                              color={(order?.currentStatus?.status === "ordered" || order?.transactionId) ? "green" : "gray"}
                              variant="light"
                          >
                            {(order?.currentStatus?.status === "ordered" || order?.transactionId) ? "Ordered Date" : "Uploaded Date"}
                          </Badge>
                      }
                      <Text>
                        {order?.orderDate &&
                        ((order?.statusHistory?.[0]?.status === "ordered" || order?.currentStatus?.status === "ordered" || order?.transactionId))
                            ? moment(order?.orderDate.toDate()).format("MMMM DD YYYY")
                            : order?.createdAt
                                ? moment(order?.createdAt.toDate()).format("MMMM DD YYYY")
                                : "N/A"
                        }
                      </Text>
                    </Flex>
                  </Flex>
                  {order?.shippingOption ? (
                    <Text fw="500">
                      Delivery:{" "}
                      <em>
                        {SHIPPING_OPTIONS.find(
                          (opt) => opt.value === order?.shippingOption
                        )?.label || ""}
                      </em>
                    </Text>
                  ) : null}
                  {order?.medications?.map((med) => (
                    <Flex
                      justify={"space-between"}
                      px={5}
                      gap={40}
                      key={med.itemName}
                    >
                      <MedicationTitle>{med.itemName}</MedicationTitle>
                      <MedicationTitle>{med.dosageForm}</MedicationTitle>
                      <MedicationTitle>
                        Quantity: {med.quantity}
                      </MedicationTitle>
                      <MedicationTitle>{med.price}</MedicationTitle>
                      <Flex direction={"column"} gap={"sm"}>
                        <NonClickableCheckbox
                          readOnly
                          label={"Auto Refill"}
                          checked={med?.autoRefill}
                        />
                      </Flex>
                    </Flex>
                  ))}
                  <Text fw="500">
                    Total Amount{" "}
                    {order?.totalAmount !== null &&
                    order?.totalAmount !== undefined
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(order?.totalAmount)
                      : "$0.00"}
                  </Text>
                </Flex>
              </Card>
              <Flex direction={"column"} mt={"sm"}>
                <Title style={{fontSize:20}}>Notes By Patient</Title>
                <Text size="sm">
                  {order?.notes ?? <em>None</em>}
                </Text>
              </Flex>

              <Flex direction={"column"} gap={5} h={"40%"}>
                <Flex gap={10} align={"center"}>
                  <Title style={{fontSize:20}}>Internal Notes</Title>
                  <ActionIcon
                    onClick={() => editInternalNotes(order)}
                    variant="transparent"
                  >
                    <Image src={assets.icons.edit} w={20} h={20} />
                  </ActionIcon>
                </Flex>
                <Card radius={"lg"} bg={colors.bg} h={"100%"}>
                  {order?.internalNotes}
                </Card>
              </Flex>
            </Flex>
          </Flex>
          {allOrders?.length>0 && (
              <Flex direction="column" gap={20} mt={30}>
                <Title>Order History</Title>
                <Table striped highlightOnHover>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Order ID</Table.Th>
                      <Table.Th>Tran ID</Table.Th>
                      <Table.Th>Uploaded Date</Table.Th>
                      <Table.Th>Ordered Date</Table.Th>
                      <Table.Th>Rx Number</Table.Th>
                      <Table.Th>Status</Table.Th>
                      <Table.Th>Total</Table.Th>
                      <Table.Th>Actions</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {allOrders?.map((order) => (
                        <Table.Tr key={order.id}>
                          <Table.Td>{order.id}</Table.Td>
                          <Table.Td>{order?.transactionId ?? "--"}</Table.Td>
                          <Table.Td>
                            {moment(order.createdAt?.toDate()).format("MMMM DD YYYY")}
                          </Table.Td>
                          <Table.Td>
                            {order && Array.isArray(order.statusHistory) ?
                                  <div>
                                    {(order.statusHistory[0]?.status === OrderStatus.Ordered || order?.transactionId)
                                  ? moment(order.orderDate?.toDate()).format("MMMM DD YYYY")
                                  : '--'}
                                  </div>:
                                <div>{(order.currentStatus.status === OrderStatus.Ordered || order?.transactionId) ? moment(order.orderDate?.toDate()).format("MMMM DD YYYY") : '--'}</div>
                            }
                          </Table.Td>
                          <Table.Td>{order.rxNumber}</Table.Td>
                          {!isRefunded(order) && (<Table.Td>
                            {order && Array.isArray(order.statusHistory) ?
                            <Badge
                                color={order && Array.isArray(order.statusHistory) && (order.statusHistory[0]?.status === OrderStatus.Ordered || order?.transactionId) ? "green" : "gray"}
                                variant="light"
                            >
                              {getKeyByValue(OrderStatus, order && Array.isArray(order.statusHistory) && order.statusHistory[0]?.status ?
                                  order.statusHistory[0]?.status : OrderStatus.Archived)}
                            </Badge> :
                                <Badge
                                    color={(order.currentStatus.status === OrderStatus.Ordered || order?.transactionId) ? "green" : "gray"}
                                    variant="light"
                                >
                                  {getKeyByValue(OrderStatus, order.currentStatus.status)}
                                </Badge>
                            }
                          </Table.Td>)}
                          {isRefunded(order) && (<Table.Td>
                            <Badge color={"red"} variant="light">
                              Refunded
                            </Badge>
                          </Table.Td>)}
                          <Table.Td>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(order.totalAmount || 0)}
                          </Table.Td>
                          <Table.Td>
                            <Button
                                variant="light"
                                color="blue"
                                size="xs"
                                onClick={() => viewOrder(order)}
                            >
                              View Details
                            </Button>
                          </Table.Td>
                        </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              </Flex>)}

        </Flex>
      ) : (
        <AbsolutelyCentered>Patient not found</AbsolutelyCentered>
      )}
    </PageWrapper>
  );
}

export default PatientDetails;

const Title = styled.div`
  //styleName: Paragraph 1 - Bold;
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const Label = styled.div`
  //styleName: Paragraph 2 - Bold;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;
const Value = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const MedicationTitle = styled.div`
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const Back = styled.div`
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const NonClickableCheckbox = styled(Checkbox)`
  pointer-events: none;
`;
