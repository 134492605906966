/** @format */

import { useEffect, useState } from "react";
import {getAllDocs, getFilteredDocs, upsertDoc} from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import { convertTimestamp } from "../modules";
import {User} from "../constants";

export interface UseBatchPreview {
  loading: boolean;
  getBatchPreview: () => Promise<void>;
  saveBatchPreview: (_preview: any) => Promise<boolean>;
  batchPreview: any[];
}

export const useBatchPreview = () => {
  const [batchPreview, setBatchPreview] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getBatchPreview = async () => {
    try {
      const _preview = await getAllDocs<any>(COLLECTIONS.BATCH_PREVIEW);
      setBatchPreview(
        _preview.map((d) => ({
          ...d,
          dateOfBirth: convertTimestamp(d.dateOfBirth),
        }))
      );
    } catch (error) {
      console.log("Error getting preview!", error);
    }
  };

  const getLimitedBatchPreview = async (limit: number, page_no: number, phones?: []) => {
    try {
      setLoading(true);
      // Get batchpreview with pagination
      let {docs, total} = await getFilteredDocs<any>(
          COLLECTIONS.BATCH_PREVIEW,
          limit,
          page_no,
          phones && phones.length > 0 ? {phones: ['phone', 'in', phones]} : {}
      );
      docs = docs.map((d) => ({
        ...d,
        dateOfBirth: convertTimestamp(d.dateOfBirth),
      }));
      return {docs, total};
    } catch (error) {
      console.log("Error getting patients!", error);
      setLoading(false);
      return [];
    }
  }

      const saveBatchPreview = async (_preview: any): Promise<boolean> => {
    let id = _preview?.id;
    delete _preview.id;
    const { success, code, message } = await upsertDoc<any>(
      COLLECTIONS.BATCH_PREVIEW,
      {
        ..._preview,
      },
      id,
      { createdAt: true }
    );
    if (success) {
      console.log("Batch preview data saved successfully!");
      //   notifications.show({
      //     title: "Rule action saved successfully",
      //     message: "",
      //     variant: "success",
      //   });
      // getBatchPreview();
      return true;
    } else {
      console.log("Error saving Batch preview data!", message, code);
      //   notifications.show({
      //     title: "Could not save Rule action",
      //     message: "",
      //     color: "red",
      //   });
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      // setLoading(true);
      //await getBatchPreview();
      // setLoading(false);
    })();
  }, []);

  return {
    loading,
    getBatchPreview,
    saveBatchPreview,
    batchPreview,
    getLimitedBatchPreview
  };
};
