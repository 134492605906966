import { Flex, LoadingOverlay, Table } from "@mantine/core";
import {
  AbsolutelyCentered,
  CustomPagination,
  PageHeader,
} from "../../components";
import { PageWrapper } from "../../layout";
import { Order, OrderStatus, User, colors } from "../../constants";
import styled from "styled-components";
import { useAppData } from "../../contexts/AppContext";
import { useState, useEffect } from "react";
import {
  convertTimestamp,
  displayPaginationMessageNew,
} from "../../modules";
import { PAGINATION_SIZE } from "../../constants/global";
import { highlightedText } from "../../modules/highlightedText";
import moment from "moment";
import { debounce } from "lodash";
import { algoliasearch } from "algoliasearch";

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID!,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY!
);

interface PaginationState {
  total: number;
  itemsPerPage: number;
}

function Archive() {
  const {
    ordersData: { getLimitedArchivedOrdersWithPagination, loading },
  } = useAppData();

  const [searchText, setSearchText] = useState("");
  const [activePage, setPage] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [archivedOrders, setArchivedOrders] = useState<Order[]>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    total: 0,
    itemsPerPage: PAGINATION_SIZE
  });

  useEffect(() => {
    const debouncedSearch = debounce(async (text: string, page: number) => {
      try {
        setIsPageLoading(true);

        let userIds: string[] | undefined;
        if (text) {
          const { results } = await searchClient.search({
            requests: [
              {
                indexName: process.env.REACT_APP_ENV === 'dev' ? 'patient_indx' : 'users_indx',
                query: text,
                attributesToRetrieve: ['id'],
                hitsPerPage: PAGINATION_SIZE
              }
            ]
          });
          userIds = (results[0] as any)?.hits.map((hit: any) => hit.objectID);

          // If search has no results, return early
          if (userIds.length === 0) {
            setArchivedOrders([]);
            setPagination(prev => ({ ...prev, total: 0 }));
            setIsPageLoading(false);
            return;
          }
        }

        const { orders, total } = await getLimitedArchivedOrdersWithPagination(
            PAGINATION_SIZE,
            page,
            userIds
        );

        setArchivedOrders(orders);
        setPagination(prev => ({ ...prev, total }));
        setIsPageLoading(false);
      } catch (error) {
        console.error('Error fetching archived orders:', error);
        setIsPageLoading(false);
      }
    }, 400);

    debouncedSearch(searchText, activePage);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, activePage]);

  const rows = archivedOrders.map((order) => {
    const patient = order.patient_details;
    return (
        <Table.Tr key={order.id}>
          <TableData>
            <Flex>
              <Flex direction="column" align="start">
                <div>
                  {highlightedText(
                      `${patient?.firstName} ${patient?.lastName}`,
                      searchText
                  )}
                </div>
                <div>
                  {highlightedText(
                      moment.utc(patient?.dateOfBirth).format("MM/DD/YYYY"),
                      searchText
                  )}
                </div>
              </Flex>
            </Flex>
          </TableData>
          <TableData>{patient?.email}</TableData>
          <TableData>{patient?.phone}</TableData>
          <TableData>{patient?.doctor}</TableData>
          <TableData>
            {moment(convertTimestamp(order?.currentStatus?.date as any)).format(
                "MM/DD/YYYY hh:mm"
            )}
          </TableData>
        </Table.Tr>
    );
  });

  return (
      <PageWrapper
          footer={
            <CustomPagination
                activePage={activePage}
                setPage={setPage}
                total={pagination.total}
                message={displayPaginationMessageNew(activePage, PAGINATION_SIZE, pagination.total)}
            />
          }
      >
        <PageHeader
            subtitle={displayPaginationMessageNew(activePage, PAGINATION_SIZE, pagination.total)}
            searchProps={{
              placeholder: "Search By Name or Date of Birth",
              value: searchText,
              onChange: (e) => setSearchText(e.target.value),
            }}
        />

        <Flex mah="80vh" style={{ overflowY: "scroll" }}>
          <LoadingOverlay visible={isPageLoading} />
          {!archivedOrders.length && !isPageLoading ? (
              <AbsolutelyCentered>Nothing found!</AbsolutelyCentered>
          ) : null}
          <Table highlightOnHover withColumnBorders withTableBorder>
            <Table.Thead
                bg={colors.bg}
                style={{ position: "sticky", top: 0, zIndex: 9 }}
            >
              <Table.Tr>
                <Header>NAME</Header>
                <Header>EMAIL</Header>
                <Header>PHONE NUMBER</Header>
                <Header>DOCTOR</Header>
                <Header>ARCHIVED</Header>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Flex>
      </PageWrapper>
  );
}

export default Archive;

const Header = styled(Table.Th)`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
`;

const TableData = styled(Table.Td)`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;
