import {useEffect, useState} from "react";
import {
  BatchRuleActionType,
  Order,
  OrderStatus,
  assets,
} from "../../../constants";
import { useAppData } from "../../../contexts/AppContext";
import {
  ActionIcon,
  Badge,
  Checkbox,
  Divider,
  Flex,
  Image,
  Menu,
  Popover,
  Text,
  Title,
  rem,
} from "@mantine/core";
import moment from "moment";
import {IconArchive, IconCopy, IconCheck, IconExternalLink, IconX} from "@tabler/icons-react";
import { openConfirmationModal } from "../../../modules";
import { closeModal } from "@mantine/modals";
import { ConfirmModalFrame } from "../../../components";
import { highlightedText } from "../../../modules/highlightedText";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";



const removeCountryCode = (phone: any) => {
  if (!phone) return ""; // If phone is undefined or null, return an empty string
  // Assuming the country code is prefixed with a + and consists of 1 digits
  return phone.replace(/^\+\d{1}/, "");
};
const POPOVER_EVENT = 'CLOSE_OTHER_POPOVERS';
function OrderItem({
  order,
  searchText = "",
  onArchived,
}: {
  order: Order;
  searchText?: string;
  onArchived?: (orderId: string) => void;
}) {
  let SHIPPING_OPTIONS = [
    { label: "Overnight UPS $33", value: "33" },
    { label: "USPS $9", value: "9" },
    { label: "UPS Ground $14", value: "14" },
    { label: "Pick Up", value: "pick-up" },
    { label: "Free USPS", value: "0" },
  ];
  let all_medication_cost = order?.medications?.reduce((total, medication) => {
    const price = typeof medication.price === 'number'
        ? medication.price
        : Number((medication.price as string).replace(/[$\s]/g, '')) || 0;
    return total + price;
  }, 0);
  if (all_medication_cost >= 100) {
    SHIPPING_OPTIONS = SHIPPING_OPTIONS.map((option) => {
      if (option.value === "9") {
        return {label: "UPS Ground $9", value: "9"};
      }
      return option;
    })
  }
  const {
    patientsData: { patients },
    ordersData: { saveOrder },
  } = useAppData();
  //let patient = patients.find((p) => p.id === order.userId);
  let patient = order.patient_details;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedAddress, setCopiedAddress] = useState(false);
  const [copiedBillingAddress, setCopiedBillingAddress] = useState(false);
  const navigate = useNavigate();
  const phone = patient?.phone;
  const phoneWithoutCountryCode = phone ? removeCountryCode(phone) : "";
  const [opened, setOpened] = useState(false);
  // ... other state declarations remain the same

  useEffect(() => {
    // Function to handle closing this popover
    const handleClosePopover = (event: CustomEvent) => {
      const clickedOrderId = event.detail.orderId;
      if (clickedOrderId !== order.id) {
        setOpened(false);
      }
    };

    // Add event listener
    window.addEventListener(POPOVER_EVENT, handleClosePopover as EventListener);

    // Cleanup
    return () => {
      window.removeEventListener(POPOVER_EVENT, handleClosePopover as EventListener);
    };
  }, [order.id]);

  const handlePopoverClick = () => {
    // Dispatch event to close other popovers
    window.dispatchEvent(
        new CustomEvent(POPOVER_EVENT, {
          detail: { orderId: order.id }
        })
    );
    // Toggle this popover
    setOpened(!opened);
  };
  const onArchiveOrder = async () => {
    debugger;
    await saveOrder({
      ...order,
      statusHistory: [
        ...[
          {
            ...order.currentStatus,
          },
        ],
        ...(order.statusHistory || []),
      ],
      currentStatus: {
        status: OrderStatus.Archived,
        date: new Date(),
      },
    });
    onArchived?.(order.id);
  };

  const confrimArchiveOrder = () => {
    openConfirmationModal({
      id: "confirm-archive-modal",
      children: (
        <ConfirmModalFrame
          onConfirm={async () => {
            closeModal("confirm-archive-modal");
            setIsSubmitting(true);
            await onArchiveOrder();
            setIsSubmitting(false);
          }}
          onCancel={() => {
            closeModal("confirm-archive-modal");
          }}
          title={`Are you sure you want to archive Order #${order.rxNumber}?`}
        />
      ),
    });
  };

  const address = `
    ${patient?.address?.street || ""} 
    ${patient?.address?.street2 || ""} 
    ${patient?.address?.city || ""} ${patient?.address?.state || ""} 
    ${patient?.address?.postalCode || ""}
  `.trim();

  const billingAddress = patient?.sameAddress
    ? address
    : `
    ${patient?.billingAddress?.street || ""} 
    ${patient?.billingAddress?.street2 || ""} 
    ${patient?.billingAddress?.city || ""} ${
        patient?.billingAddress?.state || ""
      } 
    ${patient?.billingAddress?.postalCode || ""}
  `.trim();

  const copyToClipboard = (text, setCopied) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <Flex direction={"row"} key={order.id} align={"center"} gap={15}>
      <Popover position="right" withArrow shadow="xl" width={600} opened={opened}>
        <Popover.Target>
          <ActionIcon variant="transparent" size={"md"} onClick={handlePopoverClick}>
            <Image w={20} h={20} src={assets.icons.dots} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown mah={500} style={{ overflowY: "auto" }}>
          <Flex justify="flex-end" mb={2}>
            <ActionIcon
                onClick={() => setOpened(false)}
                variant="filled"
                bg="black"
                radius="xl"
                size="sm"
                title="Close"
            >
              <IconX size={18} stroke={1.5} color="white" />
            </ActionIcon>
          </Flex>
          <Flex justify={"space-between"} gap={"lg"} w="100%">
            <Flex direction={"column"} gap={"sm"} w="100%">
              <Flex direction={"column"}>
                <Flex justify="space-between">
                  <Text fw={500}>Ordered by</Text>
                  <Flex display={"flex"} >
                  <Text fw={700}>
                    {patient?.firstName + " " + patient?.lastName}
                  </Text>
                    <ActionIcon
                        variant="subtle"
                        onClick={() => navigate(`/patient-look-up/${patient?.id}`, {
                          state: { from: '/order-status' }
                        })}
                        size="sm"
                        radius="xl"
                        title="View patient details"
                    >
                      <IconExternalLink size={18} stroke={2} />
                    </ActionIcon>
                  </Flex>
                </Flex>

                <Text size={"sm"}>Ordered # {order?.rxNumber}</Text>
                <Text>
                  Ordered Date:{" "}
                  {order?.orderDate
                    ? moment(order.orderDate.toDate()).format("MMMM DD YYYY ")
                    : "N/A"}
                </Text>

                <Flex gap={"sm"} align={"center"}>
                  <Text>Email:</Text>
                  <Text>{patient?.email}</Text>
                  <ActionIcon
                    variant="light"
                    size="sm"
                    // color={copiedEmail ? "teal" : "gray"}
                    onClick={() =>
                      copyToClipboard(patient?.email || "", setCopiedEmail)
                    }>
                    {copiedEmail ? (
                      <IconCheck size={16} />
                    ) : (
                      <IconCopy size={16} />
                    )}
                  </ActionIcon>
                </Flex>

                <Flex gap={"sm"}>
                  <Text>Species:</Text>
                  <Text>{patient?.species}</Text>
                </Flex>
                <Flex gap={"sm"}>
                  <Text>Phone:</Text>
                  <Text>{phoneWithoutCountryCode}</Text>
                </Flex>
                <Flex gap={"sm"}>
                  <Text>Date of birth:</Text>
                  <Text>
                    <Text>
                      {patient?.dateOfBirth
                        ? moment.utc(patient.dateOfBirth).format("MMMM DD YYYY")
                        : "N/A"}
                    </Text>
                  </Text>
                </Flex>
              </Flex>

              <Flex direction={"column"}>
                <Title size={"sm"}>Medications</Title>
                {order.medications.map((med, index) => {
                  return (
                    <>
                      {med?.metadata &&
                      med?.metadata![BatchRuleActionType.NoPayment] ? (
                        <Flex justify={"space-between"} gap={"lg"}>
                          <Flex direction={"column"}>
                            <Text fw={"bold"}>{index + 1}.</Text>
                            <Flex gap={"sm"}>
                              <Text>Medication name:</Text>
                              <Text>{med?.itemName}</Text>
                            </Flex>
                            <Flex gap={"sm"}>
                              <Text>Dosage form:</Text>
                              <Text>{med?.dosageForm}</Text>
                            </Flex>
                            <Flex gap={"sm"}>
                              <Text>Quantity:</Text>
                              <Text>{med?.quantity}</Text>
                            </Flex>
                            <Flex gap={"sm"} align={"center"}>
                              <Text>Price:</Text>
                              <Badge size="xs">No payment</Badge>
                            </Flex>

                            <Flex gap={"sm"}>
                              <Text>Flavor:</Text>
                              <Text>{med?.flavour}</Text>
                            </Flex>
                            <Divider my={"sm"} />
                          </Flex>
                          <Flex direction={"column"} gap={"sm"}>
                            <NonClickableCheckbox
                              readOnly
                              label={"Auto Refill"}
                              checked={med?.autoRefill}
                            />
                          </Flex>
                        </Flex>
                      ) : (
                        <Flex justify={"space-between"} gap={"lg"}>
                          <Flex direction={"column"}>
                            <Text fw={"bold"}>{index + 1}.</Text>
                            <Flex gap={"sm"}>
                              <Text>Medication name:</Text>
                              <Text>{med?.itemName}</Text>
                            </Flex>
                            <Flex gap={"sm"}>
                              <Text>Dosage form:</Text>
                              <Text>{med?.dosageForm}</Text>
                            </Flex>
                            <Flex gap={"sm"}>
                              <Text>Quantity:</Text>
                              <Text>{med?.quantity}</Text>
                            </Flex>
                            <Flex gap={"sm"}>
                              <Text>Price:</Text>
                              <Text>${med?.price}</Text>
                            </Flex>

                            <Flex gap={"sm"}>
                              <Text>Flavor:</Text>
                              <Text>{med?.flavour}</Text>
                            </Flex>
                            <Divider my={"sm"} />
                          </Flex>
                          <Flex direction={"column"} gap={"sm"}>
                            <NonClickableCheckbox
                              readOnly
                              label={"Auto Refill"}
                              checked={med?.autoRefill}
                            />
                          </Flex>
                        </Flex>
                      )}
                    </>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>

          <Flex direction={"column"}>
            <Title size={"sm"}>Details</Title>
            {order?.pregBF ? (
              <Text size="sm">Pregnancy/Breastfeeding</Text>
            ) : null}
            {order?.shippingOption ? (
              <Text size="sm">
                Delivery:{" "}
                <em>
                  {SHIPPING_OPTIONS.find(
                    (opt) => opt.value === order?.shippingOption
                  )?.label || ""}
                </em>
              </Text>
            ) : null}
            {order?.medications?.some(
              (med) => med?.metadata![BatchRuleActionType.RequireSignature]
            ) ? (
              <div>
                {" "}
                {order?.signatureWaived ? (
                  <Text size="sm">Delivery signature waived</Text>
                ) : (
                  <Text size="sm">Delivery signature Require</Text>
                )}
              </div>
            ) : null}

            {order?.pharmacyCall ? <Text size="sm">Wants a Call</Text> : null}

            {order?.rushOrder ? <Text size="sm">Rush Order $25</Text> : null}
            <Text size="sm">Service Fee $1.50</Text>
            <Text size="sm">
              Total Amount{" "}
              {order.totalAmount !== null && order.totalAmount !== undefined
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(order.totalAmount)
                : "$0.00"}
            </Text>
          </Flex>

          <Flex direction={"column"} mt={"sm"}>
            <Title size={"sm"}>Questions</Title>
            {order?.questions?.length
              ? order?.questions.map((q) => {
                  return (
                    <Flex direction={"column"}>
                      <Text>{q.question}</Text>
                      <Text size="sm">
                        {q.none || !q.answer ? <em>None</em> : q?.answer}
                      </Text>
                    </Flex>
                  );
                })
              : null}
            <Text>Are you Pregnant / Breastfeeding?</Text>
            {order?.pregBF ? <Text>Yes</Text> : <em>None</em>}
          </Flex>

          <Flex direction={"column"} mt={"sm"}>
            <Title size={"sm"}>Notes</Title>
            <Text size="sm">
              {order.notes ? order.notes : <em>None</em>}
            </Text>
          </Flex>

          <Flex gap={20} mt={"sm"} pb={"md"} wrap={"wrap"}>
            <Flex direction={"column"} flex={1}>
              <Title size={"sm"}>Shipping Address</Title>
              <Flex align="center">
                <Text size="sm">{address}</Text>
                <ActionIcon
                  variant="light"
                  size="sm"
                  // color={copiedAddress ? "teal" : "gray"}
                  onClick={() => copyToClipboard(address, setCopiedAddress)}>
                  {copiedAddress ? (
                    <IconCheck size={16} />
                  ) : (
                    <IconCopy size={16} />
                  )}
                </ActionIcon>
              </Flex>
            </Flex>
            <Flex direction={"column"} flex={1}>
              <Title size={"sm"}>Billing Address</Title>
              <Flex align="center">
                <Text size="sm">{billingAddress}</Text>
                <ActionIcon
                  variant="light"
                  size="sm"
                  // color={copiedBillingAddress ? "teal" : "gray"}
                  onClick={() =>
                    copyToClipboard(billingAddress, setCopiedBillingAddress)
                  }>
                  {copiedBillingAddress ? (
                    <IconCheck size={16} />
                  ) : (
                    <IconCopy size={16} />
                  )}
                </ActionIcon>
              </Flex>
            </Flex>
          </Flex>
        </Popover.Dropdown>
      </Popover>
      <Flex
        style={{ cursor: "pointer" }}
        direction={"column"}
        align={"flex-start"}>
        <div style={{
          fontWeight: order.rushOrder ? 'bold' : 'normal',
          color: order.rushOrder ? 'red' : 'inherit'
        }}>
          {highlightedText(
              patient?.firstName + " " + patient?.lastName,
              searchText
          )}
        </div>
        <div>
          {" "}
          {highlightedText(
              moment.utc(patient?.dateOfBirth).format("MM/DD/YYYY"),
            searchText
          )}
        </div>
      </Flex>
      <Flex>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <ActionIcon loading={isSubmitting} variant="transparent">
              <Image src={assets.icons.archive} w={20} h={20} />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconArchive style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => confrimArchiveOrder()}>
              Archive
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Flex>
  );
}

export default OrderItem;

const NonClickableCheckbox = styled(Checkbox)`
  pointer-events: none;
`;
