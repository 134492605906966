import { PageHeader } from "../../components";
import {Flex, LoadingOverlay} from "@mantine/core";
import {
  Order,
  OrderStatus as OrderStatusEnum,
  User,
  colors,
} from "../../constants";
import { PageWrapper } from "../../layout";
import { useAppData } from "../../contexts/AppContext";
import OrderItem from "./components/OrderItem";
import {useMemo, useCallback, useState, useEffect} from "react";
import moment from "moment";
import styled from "styled-components";
import {useOrders} from "../../hooks/useOrders";


const COLUMN_HEADERS = [
  "WAITING ON RESPONSE",
  "ORDERED",
  "NO RESPONSE",
  "REFUSED",
  "WANTS A CALL"
];

const STATUS_MAP = {
  0: OrderStatusEnum.WaitingResponse,
  1: OrderStatusEnum.Ordered,
  2: OrderStatusEnum.Refused,
  3: OrderStatusEnum.KeepMeOnHold,
  4: OrderStatusEnum.WantsCall
};


const searchFilter = (orders, searchText) => {
  if (!searchText) return orders;
  const lowercaseSearch = searchText.toLowerCase();
  return orders.filter((order) => {
    const patient = order.patient_details;
    if (!patient) return false;
    const fullName = `${patient.firstName} ${patient.lastName}`.toLowerCase();
    const dob = moment.utc(patient.dateOfBirth).format("MM/DD/YYYY");
    return fullName.includes(lowercaseSearch) || dob.includes(lowercaseSearch);
  });
};

const sortByOrderDate = (a, b) => {
  if (!a.orderDate && !b.orderDate) return 0;
  if (!a.orderDate) return -1;
  if (!b.orderDate) return 1;
  return a.orderDate.seconds - b.orderDate.seconds;
};


const Header = styled.div`
  text-transform: uppercase;
  font-weight: 700;
`;

function OrderStatus() {
  const [searchText, setSearchText] = useState("");
  const {getLimitedOrders} = useOrders();
  const [loading, setLoading] = useState<boolean>(false);
  const [orders,setOrderData] = useState<Order[]>([]);
  //const [patients,setPatientData] = useState<User[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let odata = await getLimitedOrders();
      setOrderData(odata);
      //setPatientData(patients?.map((d:any) => d.user_details));
        setLoading(false);
    }
    fetchData();
  }, []);
  let filteredOrders = useMemo(() =>  searchFilter(orders,  searchText),
      [orders,  searchText]
  );

  const categorizedOrders = useMemo(() => {
    const categories = {
      waiting: [],
      ordered: [],
      noResponse: [],
      onHold: [],
      wantCall: []
    };

    filteredOrders = filteredOrders.filter(order => order.userId);
    filteredOrders.forEach(order => {
      switch (order.currentStatus.status) {
        case OrderStatusEnum.WaitingResponse:
          categories.waiting.push(order);
          break;
        case OrderStatusEnum.Ordered:
          categories.ordered.push(order);
          break;
        case OrderStatusEnum.Refused:
          categories.noResponse.push(order);
          break;
        case OrderStatusEnum.KeepMeOnHold:
          categories.onHold.push(order);
          break;
        case OrderStatusEnum.WantsCall:
          categories.wantCall.push(order);
          break;
      }
    });

    categories.ordered.sort(sortByOrderDate);
    return categories;
  }, [filteredOrders]);

  const handleOrderArchived = useCallback((archivedOrderId) => {
    setOrderData(prevOrders => prevOrders.filter(order => order.id !== archivedOrderId));
  }, []);

  const renderOrders = useCallback((orders) =>
          orders.map((order, index) => (
              <OrderItem
                  order={order}
                  key={order.id || `order_${index}`}
                  searchText={searchText}
                  onArchived={handleOrderArchived}
              />
          )),
      [searchText, handleOrderArchived]
  );

  const handleSearchChange = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);



  return (
      <PageWrapper>
        <PageHeader
            searchProps={{
              placeholder: "Search",
              value: searchText,
              onChange: handleSearchChange,
            }}
        />

        <Flex
            direction="column"
            gap="md"
            style={{ overflowY: "scroll", position: "relative" }}
        >
          <LoadingOverlay visible={loading}/>

          <Flex
              bg={colors.bg}
              p={10}
              style={{ position: "sticky", top: 0, zIndex: 9 }}>
            <Flex flex={1}>
              <Header>WAITING ON RESPONSE {process.env.REACT_APP_ENV=="dev"? '('+categorizedOrders.waiting.length+')':""}</Header>
            </Flex>
            <Flex flex={1}>
              <Header>ORDERED {process.env.REACT_APP_ENV=="dev"? '('+categorizedOrders.ordered.length+')':""}</Header>
            </Flex>
            <Flex flex={1}>
              <Header>NO RESPONSE {process.env.REACT_APP_ENV=="dev"? '('+categorizedOrders.noResponse.length+')':""}</Header>
            </Flex>
            <Flex flex={1}>
              <Header>Refused {process.env.REACT_APP_ENV=="dev"? '('+categorizedOrders.onHold.length+')':""}</Header>
            </Flex>
            <Flex flex={1}>
              <Header>WANTS A CALL {process.env.REACT_APP_ENV=="dev"? '('+categorizedOrders.wantCall.length+')':""}</Header>
            </Flex>
          </Flex>

          <Flex gap="md">
            <Flex flex={1} direction="column" gap="md">
              {renderOrders(categorizedOrders.waiting) }
            </Flex>
            <Flex flex={1} direction="column" gap="md">
              {renderOrders(categorizedOrders.ordered) }
            </Flex>
            <Flex flex={1} direction="column" gap="md">
              {renderOrders(categorizedOrders.noResponse) }
            </Flex>
            <Flex flex={1} direction="column" gap="md">
              {renderOrders(categorizedOrders.onHold) }
            </Flex>
            <Flex flex={1} direction="column" gap="md">
              {renderOrders(categorizedOrders.wantCall)}
            </Flex>
          </Flex>
        </Flex>
      </PageWrapper>
  );
}

export default OrderStatus;
