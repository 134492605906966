/** @format */

import {
  ActionIcon,
  Button,
  Flex,
  Loader,
  Menu,
  Text, Textarea,
} from "@mantine/core";
import { IconDotsVertical, IconSend2 } from "@tabler/icons-react";
import ChatBubble from "./ChatBubble";
import { openModal } from "../../../modules";
import InsertTemplate from "./InsertTemplate";
import { modals } from "@mantine/modals";
import { useAuth } from "../../../hooks/useAuth";
import { useMessages } from "../../../hooks/useMessages";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import { capitalizeWords } from "../../../constants/global";
import {doc, updateDoc} from "@firebase/firestore";
import {db} from "../../../firebase";
import {useAppData} from "../../../contexts/AppContext";
interface Props {
  userInfo: any;
  searchValue: any;
  mostRecentThread: any;
}
function ChatContainer({ userInfo, searchValue, mostRecentThread }: Props) {
  const [message, setMessage] = useState("");
  const { user } = useAuth();
  const {updateUnreadMessagesCount} = useAppData();
  const {
    fetchThreadMessages,
    messages,
    sendThreadMessage,
  } = useMessages();
  // const [messages, setMessages] = useState<any[]>([]); // Using 'any' to bypass type checking
  const [loading, setLoading] = useState(false);
  const messagesContainerRef = useRef(null);

  const handelTemplateSelect = (text) => {
    if (typeof text != "string") {
      modals.close("insert-template");
      return;
    }

    if (text.includes("[First name]")) {
      text = text.replace("[First name]", capitalizeWords(userInfo.firstName));
    }
    if (text.includes("[Last name]")) {
      text = text.replace("[Last name]", capitalizeWords(userInfo.lastName));
    }
    if (text.includes("[drName]")) {
      text = text.replace("[drName]", capitalizeWords(userInfo.doctor));
    }
    setMessage(text);
    modals.close("insert-template");
  };

  const onInsertTemplate = () => {
    openModal({
      id: "insert-template",
      children: <InsertTemplate onSelectTemplate={handelTemplateSelect} />,
    });
  };

  const sendMessage = async () => {
    setLoading(true);
    const cloudFunctionUrl =
      "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app/sendWhippySMS";
    let temp = {
      phoneNumber: userInfo?.phone,
      message: message,
    };
    axios
      .post(cloudFunctionUrl, {
        data: temp,
      })
      .then(async (response) => {
        setLoading(false);
        const res = await sendThreadMessage(
          userInfo?.mostRecentThread?.id,
          user?.uid,
          userInfo?.id,
          response?.data?.sid,
          message
        );

        if (!userInfo?.mostRecentThread?.id && res?.threadId) {
          userInfo.mostRecentThread = {
            id: res.threadId,
            lastMessage: message,
          };
          mostRecentThread(userInfo.mostRecentThread);
        }
        setMessage("");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error sending SMS:", error);
        notifications.show({
          title: "Error sending SMS!",
          message: "",
          variant: "error",
          color: "red",
        });
      });
  };

  useEffect(() => {
    const threadId = userInfo?.mostRecentThread?.id;
    const unsubscribe = fetchThreadMessages(threadId);
    return unsubscribe;
  }, [userInfo?.mostRecentThread?.id, fetchThreadMessages]);

  const filteredMessages = messages.filter((msg) =>
    msg.content.toLowerCase().includes(searchValue.toLowerCase())
  );

  // useEffect(() => {
  //   if (messagesContainerRef.current) {
  //     messagesContainerRef.current.scrollTop =
  //       messagesContainerRef.current.scrollHeight;
  //   }
  // }, [filteredMessages]); //this is causing the scroll to go to the bottom on every render and not letting to go
  // up

  // console.log("filteredMessages", filteredMessages);
  // console.log("userInfo", userInfo);


  const markAsRead = async () => {
    if (!userInfo?.mostRecentThread?.id) {
      notifications.show({
        title: "No active thread",
        message: "There's no active chat thread to mark as read.",
        color: "yellow",
      });
      return;
    }
    try {
      const threadRef = doc(db, "message-thread", userInfo.mostRecentThread.id);
      await updateDoc(threadRef, { unreadMessages: 0 });
      await updateUnreadMessagesCount();
      notifications.show({
        title: "Marked as read",
        message: "Thread has been marked as read.",
        color: "green",
      });
    } catch (error) {
      console.error("Error marking thread as read:", error);
      notifications.show({
        title: "Error",
        message: "Failed to mark thread as read. Please try again.",
        color: "red",
      });
    }
  }
  const markAsUnRead = async () => {
    if (!userInfo?.mostRecentThread?.id) {
      notifications.show({
        title: "No active thread",
        message: "There's no active chat thread to mark as unread.",
        color: "yellow",
      });
      return;
    }
    try {
      const threadRef = doc(db, "message-thread", userInfo.mostRecentThread.id);
      await updateDoc(threadRef, { unreadMessages: 1 });
      await updateUnreadMessagesCount();
      notifications.show({
        title: "Marked as unread",
        message: "Thread has been marked as unread.",
        color: "green",
      });
    } catch (error) {
      console.error("Error marking thread as unread:", error);
      notifications.show({
        title: "Error",
        message: "Failed to mark thread as unread. Please try again.",
        color: "red",
      });
    }
  }

  return (
    <Flex direction={"column"} justify={"space-between"} h={"100%"} gap={5}>
      <Flex justify={"space-between"}>
        <Text fw={500}>
          {userInfo.firstName} {userInfo.lastName}
        </Text>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <ActionIcon variant="transparent">
              <IconDotsVertical size={24} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={markAsRead}>
              Mark as Read
            </Menu.Item>
            <Menu.Item onClick={markAsUnRead}>
              Mark as Unread
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>

      <Flex
        flex={1}
        px={12}
        style={{ overflowY: "auto" }}
        direction={"column"}
        ref={messagesContainerRef}>
        <Flex gap={15} direction={"column"}>
          {filteredMessages?.map((message, index) => (
            <ChatBubble
              key={index}
              type={message.sentBy === userInfo?.id ? "received" : "sent"}
              text={message.content}
              timestamp={message.timestamp}
            />
          ))}
        </Flex>
      </Flex>

      <Flex direction={"column"} gap={10}>
        <Button
          onClick={onInsertTemplate}
          style={{ alignSelf: "flex-end" }}
          radius={"lg"}
          size="compact-md">
          Insert Template
        </Button>
        <Flex>
          <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              w="100%"
              size="lg"
              placeholder="Type here"
              radius={0}
              variant="filled"
              autosize
              minRows={2}
              maxRows={20}
              styles={{
                input: {
                  paddingRight: 70,
                },
              }}
          />

          <ActionIcon
            style={{
              position: "absolute",
              right: 30,
            }}
            size={"xl"}
            color={"black"}
            variant="transparent"
            onClick={() => sendMessage()}>
            {loading ? <Loader size={20} /> : <IconSend2 size={30} />}
          </ActionIcon>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ChatContainer;
