import { Button, Flex, LoadingOverlay, Table } from "@mantine/core";
import styled from "styled-components";
import {
  AbsolutelyCentered,
  ConfirmModalFrame,
  CustomPagination,
  PageHeader,
} from "../../components";
import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import { OrderStatusLabel, User, colors } from "../../constants";
import Papa from "papaparse";
import { useAppData } from "../../contexts/AppContext";
import { PageWrapper } from "../../layout";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import {
  chunk,
  displayPaginationMessage, displayPaginationMessageNew,
  openConfirmationModal,
} from "../../modules";
import { COLLECTIONS, PAGINATION_SIZE } from "../../constants/global";
import moment from "moment";
import { highlightedText } from "../../modules/highlightedText";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import { closeModal } from "@mantine/modals";
import { addSubcollectionDoc } from "../../repositories/firebase";
import {db} from "../../firebase";
import {doc, orderBy, query, updateDoc, where} from "@firebase/firestore";
import {collection, getDocs, Timestamp} from "firebase/firestore";
import {usePatients} from "../../hooks/usePatients";
import {debounce} from "lodash";
import {algoliasearch} from "algoliasearch";
interface PaginationState {
  total: number;
  itemsPerPage: number;
}
const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID!,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY!
);
function PatientLookUp() {
  const {
    patientsData: { savePatient,  loading },
    ordersData: { orders },
  } = useAppData();

  const [activePage, setPage] = useState(1);
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState("");
  const [sendingSms, setSendingSms] = useState<string | undefined>();
  const [patients, setPatients] = useState<User[]>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const {getLimitedPatients} = usePatients();
  const [pagination, setPagination] = useState<PaginationState>({
    total: 0,
    itemsPerPage: PAGINATION_SIZE
  });

  useEffect(() => {
    const debouncedSearch = debounce(async (text: string, page: number) => {
      try {
        setIsPageLoading(true);
        if (!text) {
          const {patients, total} = await getLimitedPatients(PAGINATION_SIZE, page, []);
          setPatients(patients);
          setIsPageLoading(false);
          setPagination(prev => ({...prev, total}));
          return;
        }

        const { results } = await searchClient.search({
          requests: [
            {
              indexName: process.env.REACT_APP_ENV==='dev' ? 'patient_indx' : 'users_indx',
              query: text,
              attributesToRetrieve: ['id'],
              hitsPerPage: 10
            }
          ]
        });
        const ids = (results[0] as any)?.hits.map((hit: any) => hit.objectID) || [];
        const { patients, total } = await getLimitedPatients(PAGINATION_SIZE, page, ids);
        setPatients(patients);
        setIsPageLoading(false);
        setPagination(prev => ({ ...prev, total }));
      } catch (error) {
        setIsPageLoading(false);
        console.error('Error fetching/searching patients:', error);
      }
    }, 400);

    debouncedSearch(searchText, activePage);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, activePage]);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target!.result as string;
        parseCsvData(text);
      };
      reader.readAsText(file);
    }
  };
  const parseCsvData = (text: string) => {
    Papa.parse(text, {
      header: true,
      complete: async (results) => {
        let data: User[] = results.data.map((datum: any) => ({
          firstName: datum["First Name"],
          lastName: datum["Last Name"],
          dateOfBirth: datum["DOB"],
          phone: datum["Cell"],
          species: datum["Species"],
          doctor:
            (datum["Dr First Name"] || "") +
            " " +
            (datum["Dr Last Name"] || ""),
        }));
        setUploading(true);

        data = data.filter(
          (datum: any) =>
            !Object.keys(datum).some((key) => datum[key] === undefined)
        );
        const promises = data.map((datum) => savePatient(datum));
        await Promise.all(promises);
        setUploading(false);
      },
    });
  };

  const confirmSendSms = (data: {
    phone: string;
    uid: string;
    rxNumber: string;
    drName: string;
    name: string;
  }) => {
    let oid = null;
    let textString = '';
    openConfirmationModal({
      id: "confirm-sms-modal",
      children: (
        <ConfirmModalFrame
          onConfirm={async () => {
            const ordersRef = collection(db, 'orders');
            //debugger;
            const rxNumberArray = Array.isArray(data.rxNumber) ? data.rxNumber : [data.rxNumber];
            const q = query(ordersRef,
                where('rxNumber', 'in', rxNumberArray),
                orderBy('createdAt', 'desc'));
            const querySnapshot = await getDocs(q);
            oid = null;
            if (!querySnapshot.empty) {
              const orderDoc = querySnapshot.docs[0];
              const orderData = orderDoc.data();
              oid = orderDoc.id;
              if (orderData.currentStatus && orderData.currentStatus.status !== 'ordered') {
                await updateDoc(doc(db, 'orders', orderDoc.id), {
                  'currentStatus.status': 'waiting-response',
                  'currentStatus.date': Timestamp.now()
                });
              }
            }
            console.log("http://"+process.env.REACT_APP_FORNTEND_URL+"?oid=" + oid+"&rxNumber="+data?.rxNumber);
            closeModal("confirm-sms-modal");
            onSendSms(
              data.phone,
                oid || "",
              data?.rxNumber || "",
              data.drName,
              data.name
            );
          }}
          onCancel={() => {
            closeModal("confirm-sms-modal");
          }}
          title={`Are you sure you want to send link to this number ${data.phone}? `}
        />
      ),
    });
  };



  // const shortenUrl = async (url: string) => {
  //   const response = await fetch('https://smolurl.com/api/links', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       url
  //     })
  //   });
  //   const data = await response.json();
  //   console.log(data);
  //   if (!data || !data.data || !data.data.short_url) {
  //     console.log('error shorting url',url);
  //     return url;
  //   }
  //   return data.data.short_url;
  // }

  const onSendSms = (
    phone: string,
    oid: string,
    rxNumber: string,
    drName: string,
    name: string
  ) => {
    if (!phone)
      return notifications.show({
        title: "Invalid phone number!",
        message: "",
        variant: "error",
      });

    if (!oid || !rxNumber) {
      return notifications.show({
        title: "User/Order does not exist!",
        message: "",
        variant: "error",
      });
    }

    if (!drName) {
      return notifications.show({
        title: "Invalid doctor name!",
        message: "",
        variant: "error",
      });
    }

    setSendingSms(oid);
    const BASE_URL =
      process.env.REACT_APP_ENV === "prod"
        ? "https://rx2text.com/"
        : "http://localhost:3000";

    //let params = `uid=${uid}&rxNumber=${rxNumber}`;
    let params = `oid=${oid}&rxNumber=${rxNumber}`;
    let link = `${BASE_URL}?${params}`;
    console.log("sms link: ", link);
    const cloudFunctionUrl =
      "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app/sendWhippySMS";

    let message = `Hey ${name}! This is The Art of Medicine Compounding Pharmacy. It looks like ${drName} prescribed you a medication. For easy and secure ordering, click here: ${link}.`;
    console.log("message", message);
    debugger;
    if (process.env.REACT_APP_ENV === 'dev') {
      console.info("SMS sent successfully | Fake | Resend Link");
      notifications.show({
        title: "Message sent successfully",
        message: "",
        variant: "success",
      });
      addSubcollectionDoc(
          COLLECTIONS.ORDERS,
          oid,
          "order-logs",
          {
            title: `Reminder Message.`,
            description: `Message sent to ${name} to complete their order.`,
          },
          {
            createdAt: true,
          }
      );
      return;
    }
    axios
      .post(cloudFunctionUrl, {
        data: {
          phoneNumber: phone,
          message: message,
        },
      })
      .then((response: { data: any }) => {
        console.info("SMS sent successfully", response.data);
        notifications.show({
          title: "Message sent successfully",
          message: "",
          variant: "success",
        });
        addSubcollectionDoc(
            COLLECTIONS.ORDERS,
            oid,
            "order-logs",
            {
              title: `Reminder Message.`,
              description: `Message sent to ${name} to complete their order.`,
            },
            {
              createdAt: true,
            }
        );
      })
      .catch((error: any) => {
        console.error("Error sending SMS:", error);
        notifications.show({
          title: "Error sending SMS!",
          message: "",
          variant: "error",
          color: "red",
        });
      })
      .finally(() => setSendingSms(undefined));
  };

  const rows = (
     patients || []
  )?.map((element:any) => {
    // here we are sorting the orders based on date | latest uploaded at the top.
    let tmp = element?.orders.filter((o) => o.userId === element.id);
    let order = tmp?.length > 1 ? tmp?.sort((a, b) => {
      if (b.createdAt.seconds !== a.createdAt.seconds) {
        return b.createdAt.seconds - a.createdAt.seconds;
      }
      return b.createdAt.nanoseconds - a.createdAt.nanoseconds;
    })[0] : tmp[0];
    return (
      <Table.Tr key={element.id}>
        <TableData>
          <Flex
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(ROUTES.PatientLookUp.path + "/" + element.id);
            }}
          >
            <Flex direction={"column"} align={"start"}>
              <div>
                {highlightedText(
                  element.firstName + " " + element.lastName,
                  searchText
                )}{" "}
              </div>
              <div>
                {highlightedText(
                  moment.utc(element.dateOfBirth).format("MM/DD/YYYY"),
                  searchText
                )}
              </div>
            </Flex>
          </Flex>
        </TableData>
        <TableData>{element.species}</TableData>
        <TableData>{element.phone}</TableData>
        <TableData>{element.doctor}</TableData>
        <TableData>
          {order?.currentStatus.status
            ? OrderStatusLabel[order?.currentStatus.status]
            : ""}
        </TableData>
        <TableData>
          <Button
            loading={element.id === sendingSms}
            onClick={async () => {
              // await new Promise((res, rej) => setTimeout(res, 2000));
              confirmSendSms({
                phone: element.phone,
                uid: element.id || "",
                rxNumber: order?.rxNumber || "",
                drName: element.doctor,
                name: element.firstName,
              });
            }}
          >
            Send Link
          </Button>
        </TableData>
      </Table.Tr>
    );

  });

  return (
    <PageWrapper
      footer={
        <CustomPagination
          activePage={activePage}
          setPage={setPage}
          total={pagination.total}
          message={displayPaginationMessageNew(activePage, PAGINATION_SIZE, pagination.total)}
        />
      }
    >
      <PageHeader
         subtitle={displayPaginationMessageNew(activePage,PAGINATION_SIZE, pagination.total)}
        searchProps={{
          placeholder: "Search By Name or Date of Birth",
          value: searchText,
          onChange: (e) => setSearchText(e.target.value),
        }}
        buttons={
          <Fragment>
            {/* <Button onClick={handleClick} radius={"xl"} size="xl">
              Upload Batch
            </Button> */}
            <input
              ref={inputRef}
              type="file"
              accept={".csv"}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />{" "}
          </Fragment>
        }
      />

      <Flex mah={"80vh"} style={{ overflowY: "scroll" }}>
        <LoadingOverlay visible={isPageLoading}/>
        {!patients.length  && !isPageLoading ? (
          <AbsolutelyCentered>Nothing found!</AbsolutelyCentered>
        ) : null}
        <Table highlightOnHover withColumnBorders withTableBorder>
          <LoadingOverlay
            visible={uploading || loading}
            loaderProps={{
              children: uploading ? "Uploading data..." : undefined,
            }}
          />

          <Table.Thead
            bg={colors.bg}
            style={{ position: "sticky", top: 0, zIndex: 9 }}
          >
            <Table.Tr>
              <Header>NAME</Header>
              <Header>SPECIES</Header>
              <Header>PHONE NUMBER</Header>
              <Header>DOCTOR</Header>
              <Header>ORDER STATUS</Header>
              <Header>ACTIONS</Header>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Flex>
    </PageWrapper>
  );
}

export default PatientLookUp;

const Header = styled(Table.Th)`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
`;

const TableData = styled(Table.Td)`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;
