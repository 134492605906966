/** @format */

import { useEffect, useState } from "react";
import { BatchRule, BatchRuleAction, RULE_ACTIONS } from "../constants";
import {getAllDocs, upsertDoc} from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import { notifications } from "@mantine/notifications";
import _ from "lodash";

export interface UseBatchRules {
  loading: boolean;
  getBatchRules: () => Promise<void>;
  saveBatchRule: (_rule: BatchRule) => Promise<boolean>;
  batchRules: BatchRule[];
  batchRuleActions: BatchRuleAction[];
  saveBatchRuleAction: (_action: BatchRuleAction) => Promise<boolean>;
}

export const useBatchRules = (): UseBatchRules => {
  const [batchRules, setBatchRules] = useState<BatchRule[]>([]);
  const [batchRuleActions, setBatchRuleActions] = useState<BatchRuleAction[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const getBatchRules = async () => {
    try {
      const _rules = await getAllDocs<BatchRule>(COLLECTIONS.BATCH_RULES);
      let _actions = await getAllDocs<BatchRuleAction>(
        COLLECTIONS.BATCH_RULE_ACTIONS
      );
      setBatchRules(_rules);
      _actions = _actions.concat([
        ...RULE_ACTIONS.map((action) => {
          return {
            createdAt: new Date(),
            createdBy: "",
            title: action.label as any,
            slug: action.slug,
          };
        }),
      ]);

      _actions = _.uniqBy(_actions, (r) => {
        return r.title.toLocaleLowerCase().replace(" ", "").replace("-", "");
      });

      setBatchRuleActions(_actions);
    } catch (error) {
      console.log("Error getting rules!", error);
    }
  };

  const saveBatchRuleAction = async (
    _action: BatchRuleAction
  ): Promise<boolean> => {
    let id = _action?.id;
    delete _action.id;
    const { success, code, message } = await upsertDoc<BatchRuleAction>(
      COLLECTIONS.BATCH_RULE_ACTIONS,
      {
        ..._action,
      },
      id,
      { createdAt: true }
    );
    if (success) {
      console.log("Rule action saved successfully!");
      notifications.show({
        title: "Rule action saved successfully",
        message: "",
        variant: "success",
      });
      getBatchRules();
      return true;
    } else {
      console.log("Error saving Rule action!", message, code);
      notifications.show({
        title: "Could not save Rule action",
        message: "",
        color: "red",
      });
      return false;
    }
  };

  const saveBatchRule = async (_rule: BatchRule): Promise<boolean> => {
    let id = _rule?.id;
    delete _rule.id;
    const { success, code, message } = await upsertDoc<BatchRule>(
      COLLECTIONS.BATCH_RULES,
      {
        ..._rule,
      },
      id
    );
    if (success) {
      console.log("Rule saved successfully!");
      notifications.show({
        title: "Rule saved successfully",
        message: "",
        variant: "success",
      });
      getBatchRules();
      return true;
    } else {
      console.log("Error saving Rule!", message, code);
      notifications.show({
        title: "Could not save Rule",
        message: "",
        color: "red",
      });
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getBatchRules();
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    getBatchRules,
    saveBatchRule,
    batchRules,
    batchRuleActions,
    saveBatchRuleAction,
  };
};
