/** @format */

import { useEffect, useState } from "react";
import {Order, OrderStatus as OrderStatusEnum, OrderStatus, UseOrders} from "../constants";
import {getAllDocs, getFilteredDocs, upsertDoc} from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import moment from "moment";
import { convertTimestamp } from "../modules";
import _ from "lodash";
import {User} from "@firebase/auth";

export const useOrders = (): UseOrders => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getOrders = async () => {
    try {
      const _orders = await getAllDocs<Order>(COLLECTIONS.ORDERS);
      let tempOrders = _orders.map((o) => {
        const latestDate = moment.max(
            moment(convertTimestamp(o?.createdAt as any)),
            moment(convertTimestamp(o?.currentStatus?.date as any))
        );
        if (
          o.currentStatus.status === OrderStatus.WaitingResponse &&
          moment().diff(
              latestDate,
            "hours"
          ) >= 48
        ) {
          return {
            ...o,
            currentStatus: {
              ...o.currentStatus,
              status: OrderStatus.Refused,
            },
          };
        } else return o;
      });
      setOrders(tempOrders);

      return _orders;
    } catch (error) {
      console.log("Error getting orders!", error);
      return [];
    }
  };

  //** It will get orders from the last 30 days and filter them based on the status
  // and only gets patients for the orders which are going to begin to shown */
  const getLimitedOrders = async () => {
    try {
      const startTime = performance.now();


      const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      const { docs: ordersData, total: totalOrders } = await getFilteredDocs<Order>(
          COLLECTIONS.ORDERS,
          null,
          null,
          {
            createdAt: ['createdAt', '>=', thirtyDaysAgo]
          }
      );

      console.log("Time taken to get orders", performance.now() - startTime);
      console.log("Main Orders length", ordersData.length);

      const validStatuses = new Set([
        OrderStatusEnum.WaitingResponse,
        OrderStatusEnum.Ordered,
        OrderStatusEnum.Refused,
        OrderStatusEnum.KeepMeOnHold,
        OrderStatusEnum.WantsCall
      ]);

      // Process and filter orders in a single pass
      const processedOrders = ordersData.reduce((acc, order) => {
        if (!validStatuses.has(order.currentStatus.status)) {
          return acc;
        }

        let processedOrder = order;

        if (order.currentStatus.status === OrderStatus.WaitingResponse) {
          const latestDate = moment.max(
              moment(convertTimestamp(order?.createdAt as any)),
              moment(convertTimestamp(order?.currentStatus?.date as any))
          );

          if (moment().diff(latestDate, "hours") >= 48) {
            processedOrder = {
              ...order,
              currentStatus: {
                ...order.currentStatus,
                status: OrderStatus.Refused,
              },
            };
          }
        }

        acc.push(processedOrder);
        return acc;
      }, [] as Order[]);

      // Get unique user IDs using Set
      const userIds = _.uniq(processedOrders.map(o => o.userId));
      console.log("Users to process length", userIds.length);


      const patientStartTime = performance.now();

      const userIdChunks = _.chunk(userIds, 30);
      const patientChunksResponses = await Promise.all(
          userIdChunks.map(chunk =>
              getFilteredDocs<User>(
                  COLLECTIONS.USERS,
                  null,
                  null,
                  {
                    id: ['id', 'in', chunk]
                  }
              )
          )
      );
      const patients = patientChunksResponses
          .flatMap(response => response.docs)
          .map((patient:any) => ({
            ...patient,
            dateOfBirth: convertTimestamp(patient.dateOfBirth as any),
          }));
      const patientsMap = new Map(patients.map(patient => [patient.id, patient]));
      const ordersWithPatients = processedOrders.map(order => ({
        ...order,
        patient_details: patientsMap.get(order.userId)
      }));

      console.log("Time taken to get pts", performance.now() - patientStartTime);
      console.log("Orders length", ordersWithPatients.length);
      console.log("Orders with patient data", ordersWithPatients);

      setOrders(ordersWithPatients);
      return ordersWithPatients;

    } catch (error) {
      console.log("Error getting orders!", error);
      return [];
    }
  }

  const getLimitedArchivedOrdersWithPagination = async (
      limit: number,
      page_no: number,
      userIds?: string[]
  ) => {
    try {
      const startTime = performance.now();

      // Build the filter conditions
      const conditions: any = {
        status: ['currentStatus.status', '==', OrderStatusEnum.Archived]
      };

      // Add user ID filter if provided
      if (userIds && userIds.length > 0) {
        conditions.userId = ['userId', 'in', userIds];
      }

      // Get archived orders with pagination
      const { docs: ordersData, total: totalOrders } = await getFilteredDocs<Order>(
          COLLECTIONS.ORDERS,
          limit,
          page_no,
          conditions
      );

      console.log("Time taken to get archived orders", performance.now() - startTime);
      console.log("Archived Orders length", ordersData.length);

      // Get unique user IDs from the filtered orders
      const orderUserIds = _.uniq(ordersData.map(o => o.userId));
      console.log("Users to process length", orderUserIds.length);

      const patientStartTime = performance.now();

      // Fetch patient details in chunks to avoid large queries
      const userIdChunks = _.chunk(orderUserIds, 30);
      const patientChunksResponses = await Promise.all(
          userIdChunks.map(chunk =>
              getFilteredDocs<User>(
                  COLLECTIONS.USERS,
                  null,
                  null,
                  {
                    id: ['id', 'in', chunk]
                  }
              )
          )
      );

      // Process patient data
      const patients = patientChunksResponses
          .flatMap(response => response.docs)
          .map((patient: any) => ({
            ...patient,
            dateOfBirth: convertTimestamp(patient.dateOfBirth as any),
          }));

      // Create a map for efficient patient lookup
      const patientsMap = new Map(patients.map(patient => [patient.id, patient]));

      // Combine orders with patient details
      const ordersWithPatients = ordersData.map(order => ({
        ...order,
        patient_details: patientsMap.get(order.userId)
      }));

      console.log("Time taken to get patients", performance.now() - patientStartTime);
      console.log("Orders with patient data", ordersWithPatients);

      return {
        orders: ordersWithPatients,
        total: totalOrders
      };

    } catch (error) {
      console.log("Error getting archived orders!", error);
      return {
        orders: [],
        total: 0
      };
    }
  }

  const saveOrder = async (
    _order: Order,
    options: { refetch: boolean } = { refetch: true }
  ): Promise<any> => {
    const { success, code, message } = await upsertDoc<Order>(
      COLLECTIONS.ORDERS,
      {
        ..._order,
      },
      _order?.id
    );
    if (success) {
      console.log("Order saved successfully!");
      if (options?.refetch) {
        //getOrders();
      }
    } else {
      console.log("Error saving Order!", message, code);
    }
  };

  useEffect(() => {
    (async () => {
      // setLoading(true);
      // await getOrders();
      //
      // setLoading(false);
    })();
  }, []);

  return {
    orders,
    loading,
    getOrders,
    saveOrder,
    getLimitedOrders,
    getLimitedArchivedOrdersWithPagination
  };
};
