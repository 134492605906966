import { useEffect, useState } from "react";
import {Order, UsePatients, User} from "../constants";
import {
  upsertDoc,
  subscribeToAllDocs,
  getAllDocsNested, getFilteredDocs,
} from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import { convertTimestamp } from "../modules";
import {Timestamp} from "firebase/firestore";
import _ from "lodash";

export const usePatients = (): UsePatients => {
  const [patients, setPatients] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getPatients = async () => {
    setLoading(true);
    try {
      const _patients = await getAllDocsNested<User>(COLLECTIONS.USERS);

      setPatients(
        _patients.map((d) => ({
          ...d,
          dateOfBirth: convertTimestamp(d.dateOfBirth as any),
        }))
      );
      setLoading(false);
      return _patients;
    } catch (error) {
      console.log("Error getting patients!", error);
      setLoading(false);
      return [];
    }
  };

  const getLimitedPatients = async (limit: number, page_no: number,ids?:[]) => {
    try {
      debugger;
      setLoading(true);
      let condition:any = {
        phones: ['phone', '>', ''] //only getting valid data
      };
      if(ids && ids.length > 0) {
        condition = {
          ids: ['id', 'in', ids]
        }
      }

      const {docs: patientsData, total} = await getFilteredDocs<User>(
          COLLECTIONS.USERS,
          limit,
          page_no,
          condition
      );

      let patients = patientsData.map((patient) => ({
        ...patient,
        dateOfBirth: convertTimestamp(patient.dateOfBirth as any),
      }));


      patients = patients.filter(p => p.phone);

      const patientIds = _.uniq(patients.map(p => p.id));

        // Get orders for the patients
      const {docs: ordersData} = await getFilteredDocs<Order>(
          COLLECTIONS.ORDERS,
          null,
          null,
          {
            userId: ['userId', 'in', patientIds]
          }
      );


      const ordersByPatient = ordersData.reduce((acc, order) => {
        if (!acc[order.userId]) {
          acc[order.userId] = [];
        }
        acc[order.userId].push(order);
        return acc;
      }, {} as Record<string, Order[]>);

      // Merge patients with their orders
      const patientsWithOrders = patients.map((patient) => ({
        ...patient,
        orders: ordersByPatient[patient.id] || []
      }));

      setPatients(patientsWithOrders);
      console.log("Patients", patientsWithOrders);

      setLoading(false);
      return {
        patients: patientsWithOrders,
        total
      };
    } catch (error) {
      console.error("Error in getLimitedPatients:", error);
      setLoading(false);
      throw error;
    }
  }

  const getDateString = (timestamp: Timestamp | Date | undefined): string => {
    if (timestamp instanceof Timestamp) {
      // Convert Timestamp to Date, then to ISO string, then take date part
      return timestamp.toDate().toISOString().split('T')[0];
    }
    if (timestamp instanceof Date) {
      return timestamp.toISOString().split('T')[0];
    }
    return ''; // or handle undefined case as needed
  };
  const savePatient = async (
    _patient: User,
    options: { refetch: boolean } = { refetch: true }
  ): Promise<any> => {
    const { success, code, message, data } = await upsertDoc<User>(
      COLLECTIONS.USERS,
      {
        ..._patient,
      },
      _patient?.id
    );
    if (success) {
      if (options?.refetch) {
        getPatients();
      }
      let _dob = getDateString(_patient.dateOfBirth);
      return {
        id: data?.id,
        key: `${_patient.firstName}|${_patient.lastName}|${_dob}|${_patient.phone}`,
      };
    } else {
      console.log("Error saving Patient!", message, code);
      return {} as User;
    }
  };

  useEffect(() => {
    (async () => {
      // setLoading(true);
      // await getPatients();
      //
      // setLoading(false);
    })();
    // const unsubscribe = subscribeToAllDocs<User>(
    //   COLLECTIONS.USERS,
    //   (updatedPatients) => {
    //     setPatients(updatedPatients);
    //     setLoading(false);
    //   },
    //   "user-logs"
    // );

    // return () => {
    //   unsubscribe();
    // };
  }, []);

  return {
    patients,
    loading,
    getPatients,
    savePatient,
    getDateString,
    getLimitedPatients
  };
};
