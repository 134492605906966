/** @format */

import { useEffect, useState, useCallback } from "react";
import { MessageThread, User } from "../constants";
import {
  getAllDocs,
  upsertDoc,
  getDocsByQuery,
  upsertChatThread,
  sendMessage,
  fetchMessages,
  fetchAllUsersWithChatThreads,
  fetchChatThreadsForUser,
  // replyToMessageIfExists,
  subscribeToUserInfo,
  subscribeToThreadUpdates,
} from "../repositories/firebase";
import {COLLECTIONS, PAGINATION_SIZE} from "../constants/global";
import { notifications } from "@mantine/notifications";
import { Order } from "../constants";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase";
import {limit, orderBy, query, startAfter, where} from "@firebase/firestore";
import {algoliasearch} from "algoliasearch";
import {usePatients} from "./usePatients";

interface SendMessageResponse {
  threadId: string;
  mostRecentThread: any;
}
export interface UseMessages {
  loading: boolean;
  updateUser: (
    data: any,
    medication: any,
    id: any,
    orderId: any
  ) => Promise<void>;
  getRecentorder: (id: any) => Promise<object>;
  getMessages: (lastMessage: string) => Promise<void>;
  chatUsers: any[]; // Define the type of `chatUsers` if possible, e.g., `User[]` if you have a `User` type defined
  fetchChatUsers: () => Promise<any[]>; // As specified, returns a promise that resolves to an array
  cleanupListeners: () => void; // Assuming there's a method to cleanup listeners
  // fetchThreadMessages: (id: any) => Promise<any[]>;
  fetchThreadMessages: (id: any) => void; // Changed return type as it no longer returns directly
  addThreadListener: (threadId: any, userId: any) => void;
  updateUsers: (updatedUsers: any) => void;

  sendThreadMessage: (
    threadId: any,
    senderId: any,
    receiverId: any,
    messageId: any,
    message: any
  ) => Promise<SendMessageResponse>;
  messages: MessageThread[];
  hasMore: boolean;
  loadMore: () => Promise<void>;
  searchUsers: (searchTerm: string) => Promise<void>;
  searchUsersList: any[];
  setChatUsers: React.Dispatch<React.SetStateAction<any[]>>;
  setSearchUsersList: React.Dispatch<React.SetStateAction<any[]>>;
}
const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID!,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY!
);
export const useMessages = (): UseMessages => {
  // const [messages, setMessages] = useState<MessageThread[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [chatUsers, setChatUsers] = useState<any[]>([]);
  const [searchUsersList, setSearchUsersList] = useState<any[]>([]);
  const {getLimitedPatients} = usePatients();
  let unsubscribeFns = [];
  const getMessages = async (user: string) => {
    // await replyToMessageIfExists('(215) 215-2155');
    try {
      const _messages = await getAllDocs<MessageThread>(
        COLLECTIONS.MESSAGE_THREAD,
        user
      );
      setMessages(_messages);
    } catch (error) {
      console.log("Error getting messages!", error);
    }
  };

  const getRecentorder = async (id: any) => {
    try {
      if (id === undefined) {
        return [];
      }

      const _orders = await getDocsByQuery<Order>(
        COLLECTIONS.ORDERS,
        "userId",
        id
      );
      return _orders;
    } catch (error) {
      console.log("Error getting orders!", error);
      return [];
    }
  };

  const updateUser = async (
    dataTemp: any,
    medications: any,
    id: any,
    orderId: any
  ) => {
    try {
      //remove order and mostRecentThread  key from dataTemp
      delete dataTemp.order;
      delete dataTemp.mostRecentThread;
      delete dataTemp.id;
      const { success, code, message, data } = await upsertDoc<User>(
        COLLECTIONS.USERS,
        dataTemp,
        id
      );
      const medicationTemp = {
        medications: medications,
      };
      if (orderId !== undefined) {
        await upsertDoc(COLLECTIONS.ORDERS, medicationTemp, orderId);
      }

      if (success) {
        notifications.show({
          title: "Patient updated successfully",
          message: "",
          variant: "success",
        });
        // console.log("Success!", message);
        // return { id: data?.id, data: data?.phone};
      } else {
        notifications.show({
          title: message,
          message: "",
          variant: "error",
        });
        console.log("Error saving Patient!", message, code);
      }
    } catch (error) {
      console.error("Error!", error);
    }
  };

  // const fetchChatUsers = async () => {
  //   try {
  //     const results = await fetchAllUsersWithChatThreads();
  //     return results;
  //   } catch (error) {
  //     console.error("Error!", error);
  //   }
  // };

  const [lastThreadTimestamp, setLastThreadTimestamp] = useState(null);
  const [lastLoadedThread, setLastLoadedThread] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 10; // Adjust as needed
  const [unsubscribeFnsNew, setUnsubscribeFnsNew] = useState<(() => void)[]>([]);
  const chunkArray = (array: string[], chunkSize: number): string[][] => {
    const chunks: string[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };
  interface Thread {
    id: string;
    users: string[];
    lastMessageTimestamp: { __time__: string };
    unreadCount: number;
    lastMessage: string;
    lastMessageSentBy: string;
    unreadMessages:number;
    lastUpdated: { __time__: string };
  }

  interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    // Add other user properties as needed
  }

  interface UserWithThread extends User {
    mostRecentThread: {
      id: string;
      lastMessageTimestamp: { __time__: string };
      unreadMessages: number;
      lastMessage: string;
      lastMessageSentBy: string;
      users: string[];
    };
  }
  const loadMore = async () => {
    const threadsRef = collection(db, "message-thread");
    let threadsQuery = query(
        threadsRef,
        orderBy("unreadMessages", "desc"),
        orderBy("lastUpdated", "desc"),
        limit(ITEMS_PER_PAGE)
    );

    // If there's a lastThreadTimestamp, use it as the starting point for the next page
    if (lastLoadedThread) {
      threadsQuery = query(
          threadsQuery,
          startAfter(lastLoadedThread.unreadMessages, lastLoadedThread.lastUpdated)
      );
    }

    const threadsSnapshot = await getDocs(threadsQuery);

    // Map the thread documents into a list of Thread objects
    const threads: Thread[] = threadsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as Thread));

    // Extract unique user IDs from all threads
    const userIds = Array.from(new Set(threads.flatMap(thread => thread.users)));

    // Fetch users for these IDs
    const userIdChunks = chunkArray(userIds, 30); // Firestore limit
    let users: User[] = [];

    for (const chunk of userIdChunks) {
      const usersRef = collection(db, "users");
      const usersQuery = query(usersRef, where("__name__", "in", chunk));
      const usersSnapshot = await getDocs(usersQuery);

      users = users.concat(usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as User)));
    }

    const userMap = Object.fromEntries(users.map(user => [user.id, user]));

    const usersWithThreads: UserWithThread[] = threads.map(thread => {
      const userId = thread.users.find(id => userMap[id]);
      const user = userId ? userMap[userId] : null;

      if (!user) return null;
      return {
        ...user,
        mostRecentThread: {
          id: thread.id,
          lastMessageTimestamp: thread.lastMessageTimestamp,
          unreadMessages: thread.unreadMessages,
          lastMessage: thread.lastMessage,
          lastMessageSentBy: thread.lastMessageSentBy,
          users: thread.users
        }
      };
    }).filter((user): user is UserWithThread => user !== null);

    // Sort users based on unread messages and lastMessageTimestamp
    usersWithThreads.sort((a, b) => {
      // First, sort by unread messages (threads with unread messages come first)
      if (typeof a.mostRecentThread.unreadMessages === 'number' && a.mostRecentThread.unreadMessages > 0) return -1;

      // Then, sort by lastMessageTimestamp if unreadMessages are the same
      const aTime = a.mostRecentThread.lastMessageTimestamp?.__time__;
      const bTime = b.mostRecentThread.lastMessageTimestamp?.__time__;

      if (aTime && bTime) {
        return bTime.localeCompare(aTime); // Descending order (latest first)
      }

      // If one of the timestamps is missing, put the thread with a timestamp first
      if (aTime) return -1;
      if (bTime) return 1;

      // If both timestamps are missing, maintain original order
      return 0;
    });

    if (usersWithThreads.length < ITEMS_PER_PAGE) {
      setHasMore(false);
    }
    const lastDoc = threadsSnapshot.docs[threadsSnapshot.docs.length - 1];
    if (lastDoc) {
      setLastLoadedThread({
        unreadMessages: lastDoc.data().unreadMessages,
        lastUpdated: lastDoc.data().lastUpdated
      });
    } else {
      setLastLoadedThread(null);
    }
    // Clean up existing listeners before setting up new ones
    unsubscribeFnsNew.forEach((unsub) => unsub());
    const newUnsubscribeFnsNew: (() => void)[] = [];

    // Set up real-time listeners for each user's document and most recent thread
    usersWithThreads.forEach((user) => {
      const userUnsub = subscribeToUserInfo(user.id, (updatedUser) => {
        setChatUsers((currentUsers) =>
            currentUsers.map((u) =>
                u.id === user.id ? { ...u, ...updatedUser } : u
            )
        );
      });
      newUnsubscribeFnsNew.push(userUnsub);

      if (user.mostRecentThread) {
        const threadUnsub = subscribeToThreadUpdates(
            user.mostRecentThread.id,
            (updatedThread) => {
              setChatUsers((currentUsers) =>
                  currentUsers.map((u) =>
                      u.id === user.id ? { ...u, mostRecentThread: updatedThread } : u
                  )
              );
            }
        );
        newUnsubscribeFnsNew.push(threadUnsub);
      }
    });

    setUnsubscribeFnsNew(newUnsubscribeFnsNew);

    // Append new users with threads to the current list of chat users
    setChatUsers(prevUsers => [...prevUsers, ...usersWithThreads]);
    setPage(prevPage => prevPage + 1);
    setLastThreadTimestamp(threadsSnapshot.docs[threadsSnapshot.docs.length - 1]?.data().lastUpdated || null);
  };

  const searchUsers = async (searchTerm: string) => {
    if (!searchTerm.trim()) {
      // If the search term is empty, reset to initial state
      setChatUsers([]);
      setSearchUsersList([]);
      setHasMore(true);
      setPage(1);
      setLastThreadTimestamp(null);
      await loadMore();
      return;
    }

    const { results } = await searchClient.search({
      requests: [
        {
          indexName: process.env.REACT_APP_ENV==='dev' ? 'patient_indx' : 'users_indx',
          query: searchTerm,
          attributesToRetrieve: ['id'],
          hitsPerPage: 10
        }
      ]
    });

    // Extract patient IDs from search results
    const ids = (results[0] as any)?.hits.map((hit: any) => hit.objectID) || [];

    // Fetch detailed patient data using the IDs
    const { patients, total } = await getLimitedPatients(PAGINATION_SIZE, 1, ids);


    const users = patients as any;

    if (users.length === 0) {
      // No users found, set empty results
      setChatUsers([]);
      setHasMore(false);
      setLastThreadTimestamp(null);
      return;
    }

    const userIds = users.map(user => user.id);

    const threadsRef = collection(db, "message-thread");
    const threadsQuery = query(
        threadsRef,
        where("users", "array-contains-any", userIds),
        orderBy("lastUpdated", "desc")
    );

    const threadsSnapshot = await getDocs(threadsQuery);
    const threads: Thread[] = threadsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as Thread));

    const usersWithThreads: UserWithThread[] = users.map(user => {
      const userThread = threads.find(thread => thread.users.includes(user.id));
      if (!userThread) return null;

      return {
        ...user,
        mostRecentThread: {
          id: userThread.id,
          lastMessageTimestamp: userThread.lastMessageTimestamp,
          unreadMessages: userThread.unreadMessages,
          lastMessage: userThread.lastMessage,
          lastMessageSentBy: userThread.lastMessageSentBy,
          users: userThread.users
        }
      };
    }).filter((user): user is UserWithThread => user !== null);

    // Sort users based on unread messages and lastMessageTimestamp
    usersWithThreads.sort((a, b) => {
      if (a.mostRecentThread.unreadMessages > b.mostRecentThread.unreadMessages) return -1;
      if (a.mostRecentThread.unreadMessages < b.mostRecentThread.unreadMessages) return 1;

      const aTime = a.mostRecentThread.lastMessageTimestamp?.__time__;
      const bTime = b.mostRecentThread.lastMessageTimestamp?.__time__;

      if (aTime && bTime) {
        return bTime.localeCompare(aTime);
      }

      if (aTime) return -1;
      if (bTime) return 1;

      return 0;
    });

    setSearchUsersList(usersWithThreads);
    setHasMore(usersWithThreads.length === ITEMS_PER_PAGE);
    setLastThreadTimestamp(threadsSnapshot.docs[threadsSnapshot.docs.length - 1]?.data().lastUpdated || null);
  };

  const fetchChatUsers = useCallback(async () => {
    const users = await fetchAllUsersWithChatThreads();
    setChatUsers(users);

    // Clean up any existing listeners before setting up new ones
    unsubscribeFns.forEach((unsub) => unsub());
    unsubscribeFns = [];

    // Set up real-time listeners for each user's document and most recent thread
    users.forEach((user) => {
      const userUnsub = subscribeToUserInfo(user.id, (updatedUser) => {
        setChatUsers((currentUsers) =>
          currentUsers.map((u) =>
            u.id === user.id ? { ...u, ...updatedUser } : u
          )
        );
      });
      unsubscribeFns.push(userUnsub);

      if (user.mostRecentThread) {
        const threadUnsub = subscribeToThreadUpdates(
          user.mostRecentThread.id,
          (updatedThread) => {
            setChatUsers((currentUsers) =>
              currentUsers.map((u) =>
                u.id === user.id ? { ...u, mostRecentThread: updatedThread } : u
              )
            );
          }
        );
        unsubscribeFns.push(threadUnsub);
      }
    });

    return users;
  }, []);

  const addThreadListener = (threadId, userId) => {
    const threadUnsub = subscribeToThreadUpdates(threadId, (updatedThread) => {
      console.log("Thread updated!", updatedThread);
      setChatUsers((currentUsers) =>
        currentUsers.map((u) =>
          u.id === userId ? { ...u, mostRecentThread: updatedThread } : u
        )
      );
    });
    unsubscribeFns.push(threadUnsub);
    console.log("users", chatUsers);
  };

  const updateUsers = (updatedUser) => {
    //handle subscribe to thread
    // setChatUsers((currentUsers) =>
    //   currentUsers.map((u) => (u.id === updatedUser.id ? updatedUser : u))
    // );

    const threadUnsub = subscribeToThreadUpdates(
      updatedUser.mostRecentThread.id,
      (updatedThread) => {
        setChatUsers((currentUsers) =>
          currentUsers.map((u) =>
            u.id === updatedUser.id
              ? { ...u, mostRecentThread: updatedThread }
              : u
          )
        );
      }
    );
    unsubscribeFns.push(threadUnsub);
  };

  const cleanupListeners = useCallback(() => {
    unsubscribeFns.forEach((unsub) => unsub());
    unsubscribeFns = [];
  }, []);

  // const fetchThreadMessages2 = async (threadId: any) => {
  //   try {
  //     if (threadId === undefined || threadId === null) {
  //       return [];
  //     }
  //     const results = await fetchMessages(threadId);
  //     return results;
  //   } catch (error) {
  //     console.error("Error!", error);
  //   }
  // };

  // const fetchThreadMessages22 = useCallback((threadId: any) => {
  //   if (threadId === undefined || threadId === null) {
  //     return;
  //   }
  //   // Setup the real-time listener
  //   return fetchMessages(threadId, (newMessages) => {
  //     setMessages(newMessages);
  //   });
  // }, []);

  const fetchThreadMessages = useCallback((threadId: any) => {
    if (threadId === undefined || threadId === null) {
      setMessages([]);
      return () => {}; // Return a no-op if no thread ID
    }

    // Return the unsubscribe function from the subscription
    return fetchMessages(threadId, (newMessages) => {
      setMessages(newMessages);
    });
  }, []);

  const sendThreadMessage = async (
    threadId: any,
    senderId: any,
    receiverId: any,
    messageId: any,
    message: any
  ) => {
    try {
      if (threadId === undefined) {
        threadId = await upsertChatThread(senderId, receiverId, message);
      }
      const res = await sendMessage(
        threadId,
        senderId,
        receiverId,
        messageId,
        message
      );
      const response = {
        threadId: threadId,
        mostRecentThread: res,
      };
      return response;
    } catch (error) {
      console.error("Error!", error);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      // await getMessages("test");
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    getMessages,
    updateUser,
    messages,
    getRecentorder,
    fetchChatUsers,
    fetchThreadMessages,
    sendThreadMessage,
    chatUsers,
    cleanupListeners,
    addThreadListener,
    updateUsers,
    hasMore,
    loadMore,
    searchUsers,
    searchUsersList,
    setChatUsers,
    setSearchUsersList,
  };
};
