/** @format */

import { Flex, Group, Pagination } from "@mantine/core";
import styled from "styled-components";
import { colors } from "../constants";

function CustomPagination({
  total = 1,
  activePage = 1,
  setPage = () => {},
  message = "",
  searchText = "",
}: {
  total?: number;
  activePage?: number;
  setPage?: (p: number) => void;
  message?: string;
  searchText?: string;
}) {
  //   const items = data[activePage - 1].map((item: any) => (
  //     <Text key={item.id}>
  //       id: {item.id}, name: {item.name}
  //     </Text>
  //   ));

  return (
      searchText === "" ? (
          <Flex w={"100%"} align={"center"} justify={"space-between"} mt={"sm"}>
      <Subtitle>{message}</Subtitle>
      <Pagination.Root total={total} value={activePage} onChange={setPage}>
        <Group gap={5} justify="center">
          <Pagination.Previous
            style={{
              borderRadius: 15,
              backgroundColor: colors.primaryColor,
              color: "white",
            }}
          />

          <Pagination.Items />
          <Pagination.Next
            style={{
              borderRadius: 15,
              backgroundColor: colors.primaryColor,
              color: "white",
            }}
          />
        </Group>
      </Pagination.Root>
    </Flex>) : null
  );
}

export function displayPaginationMessageNew(
    activePage: number,
    itemsPerPage: number,
    totalRecords: number
): string {
    if (totalRecords === 0) return "No records found";

    // Calculate start and end indices
    const start = (activePage - 1) * itemsPerPage + 1;
    const end = Math.min(start + itemsPerPage - 1, totalRecords);

    // Format the pagination message
    return `Showing ${start} - ${end} of ${totalRecords}`;
}

export default CustomPagination;

const Subtitle = styled.div`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #b3b3b3;
`;
